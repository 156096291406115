


let mobileProductFilter = {};

(function($) {
    mobileProductFilter = {
        $btnShowMobileFilter: $("#btn-show-mobile-filter"),
        $btnCloseMobileFilter: $("#close-mobile-filter"),
        $theMobileFilter: $('#mobile-filter'),
        init: function() {
            this.$btnShowMobileFilter.on('click', {parent: this}, this.toogleMobileFilter);
            this.$btnCloseMobileFilter.on('click', {parent: this}, this.toogleMobileFilter);
            $(document).on('woof_ajax_done', {parent: this}, this.toogleMobileFilter);
        },
        toogleMobileFilter: function(e) {
            e.preventDefault();
            const parent = e.data.parent;
            parent.$theMobileFilter.toggleClass('opacity-100 -translate-x-full invisible');
        }
    }
 })(jQuery);


export default mobileProductFilter;
