


let enquiryForm = {};

(function($) {
    enquiryForm = {
        $btnShowEnquiryModal : $('#enquiry-button'),
        $enquiryModal: $('#enquiryModal'),
        $btnCloseButton: $('#closeEnquiryModal'),
        $enQuiryForm: $('#enquiryForm'),
        $loader: $('#enquiryModal #loader'),
        $result: $('#enquiryForm #result'),
        init: function() {
            this.$btnShowEnquiryModal.on('click', () => this.showModal());
            this.$btnCloseButton.on('click', () => this.closeModal());

            //Submit form

            this.$enQuiryForm.on('submit', {enquiryFormObj: this}, this.submitForm);
        },
        showModal: function() {
            //console.log(this);
            this.$enquiryModal.removeClass('hidden');
        },
        closeModal() {
            this.$enquiryModal.addClass('hidden');
        },
        submitForm(e) {
            e.preventDefault();
            //console.log(e.data.enquiryFormObj);
            const data = $(this).serialize();

            $.ajax({
                type: "POST",
                url: ajax_url.adminurl,
                dataType: 'json',
                data: data,
                beforeSend: function(){ 
                    e.data.enquiryFormObj.$loader.removeClass('hidden');
                },
                success: function (data) {
                    // hide_overlay_loader();
                    // jQuery('#alert_add_cart').modal('show');
                    // jQuery('.after_add_cart_html').html(data.msg);
                    e.data.enquiryFormObj.$loader.addClass('hidden');
                    e.data.enquiryFormObj.$enQuiryForm.find('#form-body').addClass('hidden');
                    e.data.enquiryFormObj.$enQuiryForm.find('#form-footer').addClass('hidden');
                    e.data.enquiryFormObj.$result.html(data.msg).removeclass('hidden');
                }
            });
        }
    }
 })(jQuery);


export default enquiryForm;

