
import enquiryForm from './enquiry';
import mainMenu from './mainMenu';
import variationAddToCart from "./variation-bulk-add-to-cart";
import mobileMenu from "./mobileMenu";
import {isPage} from "./helper";
import {descResponsiveTable} from "./misc";
import mobileProductFilter from "./mobileProductFilter";

mainMenu.init();
mobileMenu.init();
mobileProductFilter.init();
//console.log(mobileMenu.buildStructure());

jQuery(document).ready(() => {
      enquiryForm.init();
      variationAddToCart.init();
});

if(isPage('single-product')) {
      descResponsiveTable();
}

jQuery('.rnIconSearch').click(function(){
      //jQuery('form.search-form').toggleClass('formHide');
});

