


let mobileMenu = {};

(function($) {
    mobileMenu = {
        $burgerBtn: $("#burger-btn"),
        $mainMenuEl: $('.main-menu .siteMainNavigation'),
        $parentMenuItem: '',
        $mobileMenuEl:'',
        open: false,
        init: function() {
            this.buildStructure();
            this.$burgerBtn.on('click', {parent: this}, this.show);
            this.$parentMenuItem.on('click', this.showChildMenu);
        },
        showChildMenu: function(e) {
            e.preventDefault();
            $(this).parent().toggleClass('open')
        },
        show: function(e) {
            e.preventDefault();
            const parent = e.data.parent;
            parent.$mobileMenuEl.toggleClass('opacity-100 -translate-x-full invisible');
            parent.open = !parent.open;
            parent.changeBurgerBtnIcon(parent.open);
        },
        changeBurgerBtnIcon: function(openState) {
            let icon = openState ?  `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>` : ` <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>`;

            this.$burgerBtn.html(icon);
        },
        buildStructure: function() {
            const html = `<div class="mobile-menu lg:hidden shadow absolute w-full left-0 bg-white opacity-0 transition-all -translate-x-full duration-500 invisible"><ul class='list-none m-0'>${this.$mainMenuEl.html()}</ul></div>`;
            $('#main-menu-wrapper').after(html);
            this.$parentMenuItem = $('.mobile-menu > ul > li.menu-item-has-children > a');
            this.$mobileMenuEl = $('.mobile-menu');
        },
    }
 })(jQuery);


export default mobileMenu;
