

import {toggleSideCartOverlay} from "./sideCartOverlay";

let mainMenu = {};
let searchView = {};

(function($) {
    searchView = {
        $searchView : $("#header-searchview"),
        $btnSearchIcon : $('#search-icon'),
        $btnCloseSearchView: $("#close-searchview"),
        init: function(){
			// Handle Event
			this.$btnSearchIcon.on('click', {parent: this}, this.show);
			this.$btnCloseSearchView.on('click', {parent: this}, this.close);
        },
        toggleDisplay: async function(){
            //const parent = this;
            return setTimeout(() => {
                this.$searchView.toggleClass('invisible opacity-0');
            }, 500);
        },
        toggleSlideForm: async function() {
            return setTimeout(() => {
                this.$searchView.find('.searchview-bar').toggleClass('invisible opacity-0 translate-x-1/3');
            }, 500);
        },
        show: async function(e) {
            e.preventDefault();
			const parent = e.data.parent;
            // Add overlay class to search
            await mainMenu.toggleScaleElements();
            await parent.toggleDisplay();
            await parent.toggleSlideForm();
        },
        close: async function(e) {
            e.preventDefault();
			const parent = e.data.parent;
            await parent.toggleSlideForm();
            await parent.toggleDisplay();
            await mainMenu.toggleScaleElements();
        }
    }
})(jQuery);


(function($) {
    mainMenu = {
		$mainMenuWrapper: $('#main-menu-wrapper'), 
        $mainNavi: $('.main-menu .siteMainNavigation'),
        $mainNaviItem : $('.main-menu .siteMainNavigation > li'),
        subPanel: {
                offsetTop: 0,
                width:0,
        },
        init: function(){
			this.calSubPanelOffset();

			// Handle Event
			this.$mainNaviItem.on('mouseenter', this.setUpSubPanel);
			this.$mainNaviItem.on('mouseleave', this.hidePanel);
        },
        calSubPanelOffset: function() {
			this.subPanel.offsetTop = this.$mainNavi.offset().top + this.$mainNavi;
			this.subPanel.width = window.innerWidth;
        },
        setUpSubPanel: function() {
			$(this).find('> .sub-menu').addClass('show');
        },
        hidePanel: function() {
			$(this).find('> .sub-menu').removeClass('show')
        },
        toggleScaleElements: async function() {
            return setTimeout(() => {
                this.$mainMenuWrapper.find('a').toggleClass('scale-0 opacity-0');
                this.$mainMenuWrapper.find('.cfw-side-cart-quantity-wrap').toggleClass('scale-0 opacity-0');
            }, 500)
        },
    }
 })(jQuery);

searchView.init();

export default mainMenu;

