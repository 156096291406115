let variationAddToCart = {};
let productModal = {};


(function($) {
    productModal = {
        $modalWrapper : $("#product-variation-dialog"),
        $closeModalBtn : $("#product-variation-dialog .close"),
        status: 'error', // Error, Success, Warning,
        message: '',
        bottomActions : '',
        init: function() {
            this.$closeModalBtn.on('click', () =>  this.hide());
        },
        setDialog: function(modalArgs) {
            this.status  = modalArgs.status;
            this.message = modalArgs.message;
            this.bottomActions = modalArgs.bottomActions;
            this.buildModal();
        },
        show: function() {
            this.$modalWrapper.removeClass('hidden');
        },
        hide: function() {
            this.$modalWrapper.addClass('hidden');
        },
        buildModal: function() {
            this.$modalWrapper.find('.message').html(this.message);
            this.$modalWrapper.find('.actions').html(this.bottomActions);

            if( this.status === 'error' ) {
                this.$modalWrapper.find('.icon').html(` <div class='bg-red-100 h-12 w-12 rounded-full flex items-center justify-center sm:mx-0 sm:h-10 sm:w-10'>
                <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                </svg>
              </div>`);
            } else {
                this.$modalWrapper.find('.icon').html(` <div class='bg-green-100 h-12 w-12 rounded-full flex items-center justify-center sm:mx-0 sm:h-10 sm:w-10'>
                <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              </div>`);
            }
        }
    }

    variationAddToCart = {
        $addToCartBtn : $('.custom_add_to_cart_button'),
        init: function() {
            // Handle add to cart events
            this.$addToCartBtn.on('click', {variationAddToCartObj: this}, this.addToCart );
        },
        addToCart: function() {

            productModal.init();
            
            let product_id = "";
            let variation_id =[];
            let qtyCK = jQuery('input[name = "qtyCK[]"]').map(function () {    
              if(this.value != 0 || this.value != '')
                {
                variation_id.push(jQuery(this).attr('data-vid'));
                return this.value;
              }
    
            }).get();

            product_id = jQuery('input[name = "product_id"]').val();

            const arr = qtyCK;
            const isAllZero = arr.every(item => item === '0');

            if(isAllZero==true){

                productModal.setDialog({
                    status: 'error',
                    message: 'Please specify a quantity before adding to cart',
                    bottomActions:''
                });
    
                productModal.show();

                return false;
            }

            $.ajax({
                type: "POST",
                url: ajax_url.adminurl,
                dataType: 'json',
                beforeSend: function(){
                    $.blockUI({ message: null });
                },
                data: {
                    'action': 'ck_ajax_add_to_cart_single', //calls wp_ajax_nopriv_ajaxlogin
                    'product_id': product_id,
                    'variation_id': variation_id,
                    'qty': qtyCK
                },
                success: function (response) {
                    $('input[name = "qtyCK[]"]').val('');
                    
                    $.unblockUI();

                    $( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash] );
                }
            });

        }
    }
 })(jQuery);

 export default variationAddToCart;
